<template>
  <div class="imgModel">
    <!-- <el-card class="box-card"> -->
    <div slot="header" class="clearfix" style="margin-bottom: 20px">
      <el-button @click="addFile()">+ 添加文件夹</el-button>
      <el-button icon="el-icon-download" @click="downFile()">下载</el-button>
      <el-button icon="el-icon-delete" @click="delFile()">删除</el-button>
      <!-- <el-button @click="testDelDialog()">测试</el-button> -->
    </div>
    <div class="fileBox">
      <div v-for="(item, index) in fileData" :key="item.id" class="fileBoxitem" @click.stop="intoFile(item)">
        <div class="Fileimg">
          <img class="fileimg" src="./img/default_files.png" alt="" />
        </div>
        <div class="filename">
          <span class="fileTextName" :title="item.name">{{ item.name }}</span>
          <div @click.stop>
            <el-checkbox class="check_img" :disabled="item.type === 1" v-model="checkbox[index]"> </el-checkbox>
          </div>
        </div>
      </div>
    </div>
    <!-- </el-card> -->
    <el-dialog title="添加素材模型文件夹" :visible.sync="isaddFile" width="30%" :before-close="handleClose">
      <el-form :model="ruleForm" status-icon ref="ruleForm" class="demo-ruleForm">
        <el-form-item label="文件夹名" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddFileQuit()">取 消</el-button>
        <el-button type="primary" @click="saveFile()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fileData: [],
      checkbox: [],
      isaddFile: false,
      ruleForm: {
        name: ''
      }
    };
  },
  mounted() {
    this.getfileList();
  },
  methods: {
    // API式组件调用

    // testDelDialog() {
    //   this.$con({ title: '提示', detail: '', Deldisable: true })
    //     .then(() => {
    //       console.log('ok');
    //     })
    //     .catch(() => {
    //       console.log('c111111。。。');
    //     });
    // },
    async getfileList() {
      const res = await this.$apis.imgModel.fileList();
      this.fileData = res;
      this.checkbox.length = this.fileData.length;
    },
    intoFile(item) {
      this.$router.push({ path: '/imgModel/img', query: { data: item } });
    },
    addFile() {
      this.isaddFile = true;
    },
    AddFileQuit() {
      this.isaddFile = false;
      this.ruleForm.name = '';
    },
    async saveFile() {
      const { name } = this.ruleForm;
      if (name !== '') {
        const { fail } = await this.$apis.imgModel.addFile({ name });
        if (fail) {
          return false;
        }
        this.getfileList();
        this.ruleForm.name = '';
        this.isaddFile = false;
      }
    },
    handleClose() {
      this.isaddFile = false;
      this.ruleForm.name = '';
    },
    async downFile() {
      let picTypeIds = '';
      this.checkbox.forEach((item, index) => {
        if (item) {
          if (picTypeIds !== '') {
            picTypeIds = picTypeIds.concat(`,${this.fileData[index].picTypeId}`);
          } else {
            picTypeIds = picTypeIds.concat(`${this.fileData[index].picTypeId}`);
          }
        }
      });
      if (picTypeIds === '') {
        this.$message.warning('请选择需要下载的素材');
      } else {
        const res = await this.$apis.imgModel.validPic({ ids: picTypeIds, type: 2 });
        if (res) {
          this.$apis.imgModel.downloadAll({ picTypeIds });
        } else {
          return false;
        }
      }
    },
    async delFile() {
      let picTypeIds = '';
      this.checkbox.forEach((item, index) => {
        if (item) {
          if (picTypeIds !== '') {
            picTypeIds = picTypeIds.concat(`,${this.fileData[index].picTypeId}`);
          } else {
            picTypeIds = picTypeIds.concat(`${this.fileData[index].picTypeId}`);
          }
        }
      });
      if (picTypeIds === '') {
        this.$message.warning('请选择需要删除的素材');
        return false;
      } else {
        const { fail } = await this.$apis.imgModel.delFile({ picTypeIds });
        if (!fail) {
          this.getfileList();
          return false;
        }
      }
    }
  }
};
</script>
<style scoped>
.fileBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.fileBoxitem {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  width: 198px;
  height: 198px;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  margin: 10px;
  background-color: #f2f4f3;
  padding: 10px;
}
.fileBoxitem:hover {
  border: 1px solid #f39800;
}
.fileimg {
  width: 98px;
  height: 98px;
}
.Fileimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  border-radius: 3px;
  background-color: #fdffff;
}
.filename {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 40px;
}
.imgModel .fileBox .el-checkbox__inner {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
.el-checkbox__inner::after {
  /* transform: translateX(2px); */
  position: absolute;
  left: 40%;
  top: 20%;
}
.fileTextName {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
